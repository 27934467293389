import React, { useEffect, useState } from "react"
import loadable from "@loadable/component"

import { Link, graphql, useStaticQuery } from "gatsby"
import {
  EListingCardLayouts,
  IListingCardProps,
} from "@organic-return/foundation-react/src/components/Listing/ListingCard"
import { EListingVitalsLayout } from "@organic-return/foundation-react/src/components/Listing/ListingVitals"
import { ECarouselLinkPosition, ECarouselTitlePosition, ICarouselProps } from "@organic-return/foundation-react/src/components/Carousel/Carousel"
import { useSiteContext } from "../common/SiteContext"

const Carousel = loadable(() => import("@organic-return/foundation-react/src/components/Carousel"), {
  resolveComponent: (components) => components.Carousel,
})
const ListingCard = loadable(() => import("@organic-return/foundation-react/src/components/Listing/ListingCard"), {
  resolveComponent: (components) => components.ListingCard,
})

const ListingCarousel = ({ module }: any) => {
  const data = useStaticQuery(graphql`
  query ListingCarouselQuery {
      allListing(filter: {status: {ne: "Closed"}, exclusive: {eq: true}}, sort: { order: DESC, fields: price }, limit: 10) {
          nodes {
            id
            baths
            address {
              city
              county
              postal
              text
              street1
              street2
            }
            beds
            lotacres
            majorArea
            minorArea
            mlsID
            price
            sqft
            status
            subType
            subdivision
            exclusive
            type
            defaultImage {
              dimensions
              url
            }
            path
          }
      }        
      allAgilityListingCarousel {
          nodes {
            listings {
              id
              baths
              address {
                city
                county
                postal
                text
                street1
                street2
              }
              beds
              lotacres
              majorArea
              minorArea
              mlsID
              price
              sqft
              subType
              subdivision
              exclusive
              type
              defaultImage {
                dimensions
                url
              }
              path
            }
            contentID
          }
      }
  }
`)

  const siteContext = useSiteContext()

  const agilityListingCarousel = data.allAgilityListingCarousel.nodes.find((node: any) => {
    return node.contentID == module.contentID
  })

  let listings: any[]
  //If no filter was set on page module...
  if (agilityListingCarousel?.listings) {
    listings = agilityListingCarousel?.listings
  }
  //...use exclusive listings
  else {
    listings = data.allListing.nodes
  }

  const listingsCount = module.customFields.maxListings ?? 10

  let carouselListings: any[] = []
  if (listingsCount >= listings.length) {
    carouselListings = listings
  }
  else if (module.customFields.randomListingsMode === "true") {
    const pickRandomListing = (listings: any[]) => {
      const count = listings.length
      const randomListingIndex = Math.floor(Math.random() * count);
      carouselListings.push(listings[randomListingIndex])
      if (carouselListings.length < listingsCount) {
        listings.splice(randomListingIndex, 1)
        pickRandomListing(listings)
      }
      else {
        return
      }
    }
    pickRandomListing(listings)
  }
  else {
    carouselListings = listings.slice(0, listingsCount)
  }

  carouselListings.sort((a, b) => b.price - a.price)
  if (!carouselListings.length) return null
  carouselListings.forEach(similar => {
    !similar.defaultImage?.dimensions &&
      similar.defaultImage &&
      (similar.defaultImage.dimensions = [1600, 1200])
  })

  let layout: any = <></>

  //Carousel link props to be passed to Carousel component. Field values are retrieved from agility
  let linkObj: Partial<ICarouselProps> = {};
  if (module.customFields.displayLink == "true" && module.customFields.link?.href) {
    linkObj = {
      linkPosition: module.customFields.linkPosition == "slide" ? ECarouselLinkPosition.slide
        : module.customFields.linkPosition == "overlay" ? ECarouselLinkPosition.overlay
          : ECarouselLinkPosition.bottom,
      link: module.customFields.link.href,
      linkLabel: module.customFields.link.text,
    }
  }

  //Carousel props
  let carouselProps: Partial<ICarouselProps> = {
    ...linkObj,
    ...siteContext.globalListingCarouselProps,
  }

  //Carousel listing props with a default vitals layout
  let listingCardProps: Partial<IListingCardProps> = {
    vitalsLayout: module.customFields.cardLayout ?? EListingVitalsLayout.row1Border0,
    ...siteContext.globalListingCarouselCardProps,
  }

  const carouselLayout: string = module.customFields.layout

  switch (carouselLayout) {
    case "carousel-full":
      listingCardProps = {
        ...listingCardProps,
        linkText: module.customFields.cardButtonText,
        linkVitals: (module.customFields.cardButtonText && module.customFields.cardButtonText.length > 0) ? false : true
      }
      layout = (
        <Carousel
          titlePosition={ECarouselTitlePosition.overlay}
          title={module.customFields.title}
          className="text-center max-h-screen"
          {...carouselProps}
        >
          {carouselListings.map((listing: any, index: any) => (
            <ListingCard
              layout={EListingCardLayouts.composite}
              listing={listing}
              className=""
              maxWidthImage={1000}
              imageProps={{
                lazy: false,
                imgProps: {
                  className:
                    "max-h-screen w-full h-auto xl:h-full xl:object-cover xl:object-center",
                  wrapperClassName: "w-full xl:h-screen xl:max-h-screen",
                  wrapperProps: {
                    style: {
                      display: "block"
                    }
                  }
                }
              }}
              keyIndex={listing.id}
              key={listing.id}
              linkFunc={props => {
                return (
                  <Link
                    to={props.href ?? ""}
                    className={`hide-disabled text-inherit block text-center ${props.className}`}
                    key={listing.id}
                    title={props.title}
                  >
                    {props.children}
                  </Link>
                )
              }}
              {...listingCardProps}
            />
          ))}
        </Carousel>
      )
      break
    default:
      layout = (
        <Carousel
          className="slick-center-zoom"
          {...carouselProps}
          slickSettings={{
            centerMode: true,
            infinite: true,
            centerPadding: "10px",
            slidesToShow: Math.min(3, listings.length),
            arrows: true,
            slidesToScroll: 1,
            variableWidth: true,
            ...carouselProps.slickSettings
          }}
        >
          {carouselListings.map((listing, index) => (
            <ListingCard
              layout={EListingCardLayouts.vertical}
              listing={listing}
              className="max-w-[1200px] mx-auto"
              maxWidthImage={1200}
              imageClassProps="h-auto"
              linkText="View Property Details"
              imageProps={{ lazy: false }}
              keyIndex={listing.id}
              key={listing.id}
              linkFunc={props => {
                return (
                  <Link
                    to={props.href ?? ""}
                    className={`hide-disabled text-inherit block text-center mt-3 ${props.className}`}
                    key={listing.id}
                    title={props.title}
                  >
                    {props.children}
                  </Link>
                )
              }}
              {...listingCardProps}
            />
          ))}
        </Carousel>
      )
      break
  }

  const [isClient, setClient] = useState(false)
  useEffect(() => {
    setClient(true)
  }, [])

  if (!isClient && module.customFields.randomListingsMode === "true") return null

  return (
    <div className="listing-carousel">
      {module.customFields.title && carouselLayout != "carousel-full" && <h1>{module.customFields.title}</h1>}
      {layout}
    </div>
  )
}

export default ListingCarousel
